@import './components/successMessage';
.support_page {
    background-image: url(/public/images/support.jpg);
    background-color: #2e2340;
    min-height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 100px 20px;
    padding-top: 180px;
    display: flex;
    .sl_container {
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        h1 {
            color: white;
            font-size: 74px;
            line-height: 1em;
            text-align: center;
        }
        & > p {
            font-size: 20px;
            color: white;
            text-align: center;
        }
        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 650px;
            margin: 0 auto;
            width: 100%; 
            select {
                width: 100%; 
                height: 50px;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
            }
            .two_columns {
                display: flex;
                align-items: center;
                gap: 20px;
                input {
                    width: 100%; 
                    height: 50px;
                    padding: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
            textarea {
                height: 150px;
                border-radius: 5px;
                padding: 10px;
                resize: vertical;
            }
            button { 
                border: 1px solid white;
                height: 60px;
                padding: 10px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: .3s;
                letter-spacing: 1.5px;
                color: white;
                background: transparent;
                width: fit-content;
                max-width: 200px;
                font-size: 20px;
                margin: 0 auto;
                cursor: pointer;
                &:hover {
                    background: white;
                    color: black;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .support_page {   
        background-size: 200%; 
        .sl_container {
            gap: 30px;
            h1 {
                font-size: 30px;
            }
            p {
                font-size: 15px; 
            }
            form {  
                .two_columns {
                    flex-direction: column; 
                }
                textarea {
                    height: 100px; 
                } 
            }
        }
    }
}