@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.success_message {
  background: rgba(164, 255, 164, 0.6588235294);
  max-width: 650px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  animation: open_vertical 0.4s linear;
  height: 70px;
  padding: 20px;
  margin: 0 auto;
  gap: 20px;
}
.success_message i {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  border-radius: 50%;
  background: rgba(0, 255, 0, 0.3647058824);
}
.success_message p {
  color: black;
  text-align: left;
}

@keyframes open_vertical {
  from {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  to {
    height: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.support_page {
  background-image: url(/public/images/support.jpg);
  background-color: #2e2340;
  min-height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 100px 20px;
  padding-top: 180px;
  display: flex;
}
.support_page .sl_container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.support_page .sl_container h1 {
  color: white;
  font-size: 74px;
  line-height: 1em;
  text-align: center;
}
.support_page .sl_container > p {
  font-size: 20px;
  color: white;
  text-align: center;
}
.support_page .sl_container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
}
.support_page .sl_container form select {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.support_page .sl_container form .two_columns {
  display: flex;
  align-items: center;
  gap: 20px;
}
.support_page .sl_container form .two_columns input {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.support_page .sl_container form textarea {
  height: 150px;
  border-radius: 5px;
  padding: 10px;
  resize: vertical;
}
.support_page .sl_container form button {
  border: 1px solid white;
  height: 60px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: 0.3s;
  letter-spacing: 1.5px;
  color: white;
  background: transparent;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 200px;
  font-size: 20px;
  margin: 0 auto;
  cursor: pointer;
}
.support_page .sl_container form button:hover {
  background: white;
  color: black;
}

@media (max-width: 768px) {
  .support_page {
    background-size: 200%;
  }
  .support_page .sl_container {
    gap: 30px;
  }
  .support_page .sl_container h1 {
    font-size: 30px;
  }
  .support_page .sl_container p {
    font-size: 15px;
  }
  .support_page .sl_container form .two_columns {
    flex-direction: column;
  }
  .support_page .sl_container form textarea {
    height: 100px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.sl_container {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.header_container {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.header_container .sl_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
}
.header_container .sl_container .logo_link {
  width: 80px;
}
.header_container .sl_container .logo_link img {
  width: 100%;
}
.header_container .sl_container .right_buttons {
  display: flex;
  gap: 30px;
}
.header_container .sl_container .right_buttons a {
  border: 1px solid white;
  height: 60px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: 0.3s;
  letter-spacing: 1.5px;
  color: white;
}
.header_container .sl_container .right_buttons a:nth-child(1):hover {
  background: #ddb561;
  border-color: #ddb561;
}
.header_container .sl_container .right_buttons a:nth-child(2) {
  background: #ddb561;
  border-color: #ddb561;
}
.header_container .sl_container .right_buttons a:nth-child(2):hover {
  background: transparent;
  border-color: white;
  color: white;
}

@media (max-width: 768px) {
  .header_container .sl_container .right_buttons {
    width: 50%;
  }
  .header_container .sl_container .right_buttons a {
    color: black;
    font-size: 12px;
    padding: 6px;
    width: 100%;
    height: 40px;
  }
  .header_container .sl_container .right_buttons a:nth-child(1) {
    display: none;
  }
}
.home_page .hero_section {
  min-height: 100vh;
  background-image: url(/public/images/bannernew.jpg);
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
}
.home_page .hero_section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #130c1d;
  opacity: 0.55;
}
.home_page .hero_section .sl_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  position: relative;
  z-index: 1;
}
.home_page .hero_section .sl_container h1 {
  font-size: 110px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  color: white;
  line-height: 0.8em;
}
.home_page .hero_section .sl_container h1 .yellow_txt {
  color: #ddb561;
  font-size: 0.6em;
}
.home_page .hero_section .sl_container h1 .glow_txt {
  text-shadow: #fff 0px 0px 1px, #fff 0px 0px 10px, #fff 0px 0px 1px, #ff2d95 0px 0px 20px, #ff2d95 0px 0px 3px, #ff2d95 0px 0px 40px, #ff2d95 0px 0px 1px, #ff2d95 0px 0px 75px;
}
.home_page .hero_section .sl_container .video_part {
  display: flex;
  align-items: center;
  gap: 5%;
}
.home_page .hero_section .sl_container .video_part .video_container {
  display: flex;
  align-items: center;
  width: 400px;
  height: 250px;
}
.home_page .hero_section .sl_container .video_part .video_container iframe {
  height: 100%;
  width: 100%;
}
.home_page .hero_section .sl_container .video_part .video_container .thumbnail_element {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.home_page .hero_section .sl_container .video_part .video_container .thumbnail_element img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home_page .hero_section .sl_container .video_part .video_container .thumbnail_element i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.home_page .hero_section .sl_container .video_part .video_title {
  color: white;
  text-shadow: #fff 0px 0px 1px, #fff 0px 0px 10px, #fff 0px 0px 1px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 1.1em;
  max-width: 270px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 1.3em;
}
.home_page .logos_section {
  background: #1a1025;
}
.home_page .logos_section .sl_container {
  display: flex;
  align-items: center;
  min-height: 170px;
  gap: 2%;
}
.home_page .logos_section .sl_container .logo_container {
  width: 100%;
}
.home_page .logos_section .sl_container .logo_container img {
  width: 100%;
}
.home_page .logos_section .sl_container .logo_container h2 {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.home_page .about {
  background: url(/public/images/about-bg.png);
  background-size: cover;
}
.home_page .about .sl_container {
  min-height: 100vh;
  padding: 80px 20px;
  display: flex;
  align-items: center;
  gap: 40px;
}
.home_page .about .sl_container .left_container {
  width: 42%;
}
.home_page .about .sl_container .left_container img {
  width: 100%;
}
.home_page .about .sl_container .right_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
}
.home_page .about .sl_container .right_container h2 {
  color: white;
  font-size: 50px;
  line-height: 1.2em;
}
.home_page .about .sl_container .right_container h2 span {
  color: #ddb662;
}
.home_page .about .sl_container .right_container .text_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home_page .about .sl_container .right_container .text_container p {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7em;
}
.home_page .cards_section {
  background: #302441;
}
.home_page .cards_section .sl_container {
  display: flex;
  padding: 100px 20px;
  gap: 30px;
}
.home_page .cards_section .sl_container .single_card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 40px 4%;
  background: #281c39;
  flex-direction: column;
}
.home_page .cards_section .sl_container .single_card h3 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #ddb662;
}
.home_page .cards_section .sl_container .single_card p {
  text-align: center;
  font-size: 16px;
  color: white;
}
.home_page .cards_section .sl_container .single_card a {
  color: #ddb662;
  font-size: 16px;
}
.home_page .cards_section .sl_container .single_card img {
  width: 125px;
}
.home_page .packages_section {
  background: #281c39;
  padding: 100px 20px;
}
.home_page .packages_section .sl_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.home_page .packages_section .sl_container h2 {
  font-size: 54px;
  text-align: center;
  color: white;
}
.home_page .packages_section .sl_container p {
  font-size: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.731);
}
.home_page .packages_section .sl_container .packages_container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.home_page .packages_section .sl_container .packages_container .single_package {
  display: flex;
  flex-direction: column;
  background: #302441;
  width: 100%;
  padding: 40px;
  align-items: center;
}
.home_page .packages_section .sl_container .packages_container .single_package .new {
  color: #4cd855;
  font-weight: bold;
  margin-top: -20px;
  padding-bottom: 5px;
}
.home_page .packages_section .sl_container .packages_container .single_package .pack_category {
  border: 1px solid #ddb561;
  padding: 6px 15px;
  border-radius: 40px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: 600;
}
.home_page .packages_section .sl_container .packages_container .single_package h3 {
  color: white;
  font-size: 38px;
}
.home_page .packages_section .sl_container .packages_container .single_package h3 span {
  color: #ddb561;
}
.home_page .packages_section .sl_container .packages_container .single_package .pack_price {
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px;
  text-align: center;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  font-size: 40px;
  color: white;
  font-weight: bold;
  margin: 30px 0;
  border-top: 1px dashed white;
  border-bottom: 1px dashed white;
}
.home_page .packages_section .sl_container .packages_container .single_package .pack_price b {
  line-height: 1em;
}
.home_page .packages_section .sl_container .packages_container .single_package .pack_price span {
  font-size: 0.6em;
  font-weight: 300;
}
.home_page .packages_section .sl_container .packages_container .single_package .pack_price small {
  font-size: 0.4em;
  font-weight: 300;
}
.home_page .packages_section .sl_container .packages_container .single_package > a {
  border: 1px solid white;
  height: 60px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: 0.3s;
  letter-spacing: 1.5px;
  color: white;
  background: #ddb561;
  border-color: #ddb561;
  color: black;
  font-weight: bold;
}
.home_page .packages_section .sl_container .packages_container .single_package > a:hover {
  background: transparent;
  border-color: white;
  color: white;
}
.home_page .social {
  display: flex;
}
.home_page .social .single_social {
  background: #48375d;
  padding: 100px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.home_page .social .single_social:nth-child(2) {
  background: #3b2b4f;
}
.home_page .social .single_social img {
  width: 50px;
}
.home_page .social .single_social span {
  color: #ddb662;
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .home_page .hero_section {
    background-position: right center;
  }
  .home_page .hero_section .sl_container {
    padding-top: 120px;
  }
  .home_page .hero_section .sl_container h1 {
    font-size: 50px;
    line-height: 1;
    text-align: center;
  }
  .home_page .hero_section .sl_container h1 .yellow_txt {
    font-size: 0.6em;
    max-width: 70%;
    margin: 0 auto;
  }
  .home_page .hero_section .sl_container .video_part {
    flex-direction: column;
    padding: 0 20px;
    gap: 30px;
  }
  .home_page .hero_section .sl_container .video_part .video_container {
    width: 100%;
    height: 150px;
  }
  .home_page .logos_section {
    background: #1a1025;
  }
  .home_page .logos_section .sl_container {
    flex-wrap: wrap;
    gap: 0;
    max-width: 85%;
    padding: 50px 20px;
  }
  .home_page .logos_section .sl_container .logo_container {
    width: 50%;
    display: flex;
    padding: 10px;
  }
  .home_page .logos_section .sl_container .logo_container img {
    margin: 0 auto;
  }
  .home_page .logos_section .sl_container .logo_container h2 {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  .home_page .logos_section .sl_container .logo_container:nth-child(1) {
    width: 100%;
  }
  .home_page .logos_section .sl_container .logo_container:nth-child(1) h2 {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
  }
  .home_page .about .sl_container {
    flex-direction: column-reverse;
  }
  .home_page .about .sl_container .left_container {
    width: 100%;
  }
  .home_page .about .sl_container .right_container {
    gap: 15px;
    width: 90%;
  }
  .home_page .about .sl_container .right_container h2 {
    font-size: 35px;
  }
  .home_page .about .sl_container .right_container .text_container p {
    font-size: 16px;
  }
  .home_page .cards_section .sl_container {
    padding: 50px 20px;
    flex-direction: column;
  }
  .home_page .packages_section {
    padding: 50px 0px;
  }
  .home_page .packages_section .sl_container h2 {
    font-size: 35px;
  }
  .home_page .packages_section .sl_container p {
    font-size: 13px;
  }
  .home_page .packages_section .sl_container .packages_container {
    flex-direction: column;
  }
  .home_page .packages_section .sl_container .packages_container .single_package {
    padding: 40px 10px;
    gap: 10px;
  }
  .home_page .packages_section .sl_container .packages_container .single_package .pack_category {
    font-size: 12px;
  }
  .home_page .packages_section .sl_container .packages_container .single_package h3 {
    font-size: 30px;
  }
  .home_page .packages_section .sl_container .packages_container .single_package .pack_price {
    margin: 15px auto;
    font-size: 35px;
  }
  .home_page .packages_section .sl_container .packages_container .single_package > a {
    text-align: center;
    height: 40px;
    padding: 10px 20px;
  }
  .home_page .social {
    flex-direction: column;
  }
  .home_page .social .single_social {
    padding: 50px 20px;
  }
}
.footer_container {
  background: #302441;
}
.footer_container .sl_container {
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer_container .sl_container .hightlighted {
  color: #ddb561;
  text-align: center;
  width: 100%;
}
.footer_container .sl_container .text_content {
  color: #8b8297;
  font-size: 14px;
  text-align: center;
  line-height: 1.5em;
}
.footer_container .sl_container .footer_links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.footer_container .sl_container .footer_links a {
  color: #8b8297;
  transition: 0.3s;
  font-size: 15px;
}
.footer_container .sl_container .footer_links a:hover {
  color: white;
}
.footer_container .sl_container > small {
  text-align: center;
  font-size: 12px;
  color: #8b8297;
}

@media (max-width: 768px) {
  .footer_container .sl_container {
    padding: 50px 20px;
    gap: 30px;
  }
  .footer_container .sl_container .hightlighted {
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
  }
  .footer_container .sl_container .text_content {
    font-size: 12px;
  }
  .footer_container .sl_container .footer_links {
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
  .footer_container .sl_container > small {
    text-align: center;
    font-size: 12px;
    color: #8b8297;
  }
}/*# sourceMappingURL=main.css.map */