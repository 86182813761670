@import 'support';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
}
.sl_container {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: {
        left: auto; 
        right: auto;
    }
}

.header_container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    .sl_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1300px;
        .logo_link {
            width: 80px;
            img {
                width: 100%;
            }
        }
        .right_buttons {
            display: flex;
            gap: 30px;
            a {
                border: 1px solid white;
                height: 60px;
                padding: 10px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: .3s;
                letter-spacing: 1.5px;
                color: white;
                &:nth-child(1) {
                    &:hover {
                        background: #ddb561;
                        border-color: #ddb561;
                    }
                }
                &:nth-child(2) {
                    background: #ddb561;
                    border-color: #ddb561;
                    &:hover {
                        background: transparent;
                        border-color: white;
                        color: white;
                    }
                }
            }
        }
    }
} 
@media (max-width: 768px) {
    .header_container { 
        .sl_container {
            .right_buttons { 
                width: 50%;
                a { 
                    color: black;
                    font-size: 12px;
                    padding: 6px ;
                    width: 100%;
                    height: 40px; 
                    &:nth-child(1) {
                        display: none;
                    } 
                }
            }
        }
    } 
}


.home_page {
    .hero_section {
        min-height: 100vh;
        background-image: url(/public/images/bannernew.jpg);
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center; 
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: #130c1d;
            opacity: .55;
        }
        .sl_container { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 70px;
            position: relative;
            z-index: 1;
            h1 {
                font-size: 110px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-weight: bold;
                letter-spacing: 2px;
                color: white; 
                line-height: .8em;
                .yellow_txt {
                    color: #ddb561;
                    font-size: .6em; 
                }
                .glow_txt {
                    text-shadow: #fff 0px 0px 1px, #fff 0px 0px 10px, #fff 0px 0px 1px, #ff2d95 0px 0px 20px, #ff2d95 0px 0px 3px, #ff2d95 0px 0px 40px, #ff2d95 0px 0px 1px, #ff2d95 0px 0px 75px; 
                }
            }
            .video_part {
                display: flex;
                align-items: center;
                gap: 5%;
                .video_container {
                    display: flex;
                    align-items: center;
                    width: 400px;
                    height: 250px; 
                    iframe {
                        height: 100%;
                        width: 100%;
                    }
                    .thumbnail_element { 
                        height: 100%;
                        width: 100%;
                        position: relative;
                        cursor: pointer;
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                        i {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            border: 1px solid white;
                            height: 60px;
                            width:  60px;
                            border-radius: 50%;
                            color: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                        }
                    } 
                }
                .video_title {
                    color: white;
                    text-shadow: #fff 0px 0px 1px, #fff 0px 0px 10px, #fff 0px 0px 1px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px, #ff2d95 0px 0px 0px;
                    font-size: 20px;
                    text-align: center;  
                    font-weight: 700;
                    line-height: 1.1em;
                    max-width: 270px;
                    font-weight: bold;
                    letter-spacing: 1.5px; 
                    line-height: 1.3em;
                }
            }
        }
    }
    .logos_section {
        background: #1a1025;
        .sl_container {
            display: flex;
            align-items: center;
            min-height: 170px;
            gap: 2%;
            .logo_container {
                width: 100%; 
                img {
                    width: 100%;
                }
                h2 {
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }
    }
    .about {
        background: url(/public/images/about-bg.png);
        background-size: cover;
        .sl_container {
            min-height: 100vh;
            padding: 80px 20px;
            display: flex;
            align-items: center;
            gap: 40px;
            .left_container {
                width: 42%;
                img {
                    width: 100%;
                }
            }
            .right_container { 
                display: flex;
                flex-direction: column;
                gap: 30px;
                width: 50%;
                h2 {
                    color: white;
                    font-size: 50px;
                    line-height: 1.2em;
                    span {
                        color: #ddb662;
                    }
                }
                .text_container {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    p {
                        color: white;
                        font-size: 20px; 
                        font-weight: 300;
                        line-height: 1.7em;
                    }
                }
            }
        }
    }
    .cards_section {
        background: #302441;
        .sl_container{
            display: flex;
            padding: 100px 20px;
            gap: 30px;
            .single_card {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                padding: 40px 4%;
                background: #281c39;
                flex-direction: column;
                h3 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 20px;
                    color: #ddb662; 
                }
                p {
                    text-align: center;
                    font-size: 16px;
                    color: white;
                }
                a {
                    color: #ddb662;
                    font-size: 16px; 
                }
                img {
                    width: 125px;
                }
            }
        }
    }
    .packages_section {
        background: #281c39;
        padding: 100px 20px;
        .sl_container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            h2 {
                font-size: 54px;
                text-align: center;
                color: white;
            }
            p {
                font-size: 20px;
                text-align: center;
                color: rgba(255, 255, 255, 0.731);
            }
            .packages_container {
                display: flex;
                gap: 20px;
                margin-top: 20px;
                .single_package {
                    display: flex;
                    flex-direction: column;
                    background: #302441;
                    width: 100%;
                    padding: 40px;
                    align-items: center;
                    .new {
                        color: #4cd855;
                        font-weight: bold;
                        margin-top: -20px;
                        padding-bottom: 5px;
                    }
                    .pack_category {
                        border: 1px solid #ddb561;
                        padding: 6px 15px;
                        border-radius: 40px;
                        width: fit-content;
                        max-width: 80%;
                        text-align: center;
                        color: white;
                        font-size: 17px;
                        font-weight: 600;
                    }
                    h3 {
                        color: white;
                        font-size: 38px;
                        span {
                            color: #ddb561;
                        }
                    }
                    .pack_price {
                        width: fit-content;
                        padding: 20px 20px;
                        text-align: center;
                        display: flex;
                        gap: 5px;
                        align-items: flex-end;
                        font-size: 40px;
                        color: white;
                        font-weight: bold;
                        margin: 30px 0;
                        border: {
                            top: 1px dashed white;
                            bottom: 1px dashed white;
                        }
                        b {
                            line-height: 1em;
                        }
                        span {
                            font-size: .6em;
                            font-weight: 300; 
                        }
                        small {
                            font-size: .4em;
                            font-weight: 300;
                        }
                    }
                    & > a {
                        border: 1px solid white;
                        height: 60px;
                        padding: 10px 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        transition: .3s;
                        letter-spacing: 1.5px;
                        color: white;
                        background: #ddb561;
                        border-color: #ddb561;
                        color: black;
                        font-weight: bold;
                        &:hover {
                            background: transparent;
                            border-color: white;
                            color: white
                        }
                    }
                }
            }
        }
    }
    .social {
        display: flex;
        .single_social {
            background: #48375d; 
            padding: 100px 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            &:nth-child(2) {
                background: #3b2b4f;
            }
            img {
                width: 50px;
            }
            span {
                color: #ddb662;
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
}
@media (max-width: 768px) {
    .home_page {
        .hero_section {
            background-position: right center;
            .sl_container { 
                padding-top: 120px;
                h1 {
                    font-size: 50px;
                    line-height: 1; 
                    text-align: center;
                    .yellow_txt {
                        font-size: .6em;
                        max-width: 70%;
                        margin: 0 auto;
                    }
                }
                .video_part {
                    flex-direction: column;
                    padding: 0 20px;
                    gap: 30px;
                    .video_container { 
                        width: 100%;
                        height: 150px;
                    }
                }
            }
        }
        .logos_section {
            background: #1a1025;
            .sl_container {
                flex-wrap: wrap;
                gap: 0;
                max-width: 85%;
                padding: 50px 20px;
                .logo_container {
                    width: 50%; 
                    display: flex;
                    padding: 10px;
                    img { 
                        margin: 0 auto;
                    }
                    h2 {
                        color: white;
                        font-size: 20px;
                        font-weight: bold;
                    }
                    &:nth-child(1) {
                        width: 100%;
                        h2 {
                            text-align: center;
                            width: 100%;
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .about { 
            .sl_container {
                flex-direction: column-reverse;
                .left_container {
                    width: 100%; 
                }
                .right_container {
                    gap: 15px;
                    width: 90%;
                    h2 { 
                        font-size: 35px;
                    }
                    .text_container { 
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .cards_section { 
            .sl_container{
                padding: 50px 20px; 
                flex-direction: column; 
            }
        }
        .packages_section { 
            padding: 50px 0px; 
            .sl_container {
                h2 {
                    font-size: 35px;
                }
                p {
                    font-size: 13px;
                }
                .packages_container { 
                    flex-direction: column;
                    .single_package { 
                        padding: 40px 10px;
                        gap: 10px;
                        .pack_category {
                            font-size: 12px;
                        }
                        h3 { 
                            font-size: 30px;
                        }
                        .pack_price {  
                            margin: 15px auto;
                            font-size: 35px;
                        }
                        & > a {
                            text-align: center; 
                            height: 40px;
                            padding: 10px 20px; 
                        }
                    }
                }
            }
        }
        .social {
            flex-direction: column;
            .single_social { 
                padding: 50px 20px; 
            }
        }
    }
}

.footer_container {
    background: #302441;
    .sl_container {
        padding: 80px 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .hightlighted {
            color: #ddb561;
            text-align: center;
            width: 100%;
        }
        .text_content {
            color: #8b8297;
            font-size: 14px;
            text-align: center;
            line-height: 1.5em;
        }
        .footer_links {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            a {
                color: #8b8297;
                transition: .3s;
                font-size: 15px;
                &:hover {
                    color: white;
                }
            }
        }
        & > small {
            text-align: center;
            font-size: 12px;
            color: #8b8297;
        }
    }
}
@media (max-width: 768px) {
    .footer_container {
        .sl_container {
            padding: 50px 20px;
            gap: 30px;
            .hightlighted { 
                width: 80%;
                margin: 0 auto;
                font-size: 14px;
            }
            .text_content { 
                font-size: 12px; 
            }
            .footer_links {
                flex-direction: column;
                gap: 20px;
                margin: 20px 0;
            }
            & > small {
                text-align: center;
                font-size: 12px;
                color: #8b8297;
            }
        }
    }
}