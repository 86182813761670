.success_message {
    background: #a4ffa4a8;
    max-width: 650px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    animation: open_vertical .4s linear;
    // When you change these, make sure to change them in the animation as well to not break the look
    height: 70px;
    padding: 20px;
    margin: 0 auto;
    gap: 20px;
    i {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: green;
        border-radius: 50%;
        background: #00ff005d;
    }
    p {
        color: black;
        text-align: left; 
    }
}
@keyframes open_vertical {
    from {
        height: 0;
        padding: {
            top: 0;
            bottom: 0; 
        }
    } to {
        height: 70px;
        padding: {
            top: 20px;
            bottom: 20px;
        }
    }
}